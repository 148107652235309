import React, { useContext, useEffect } from "react";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { CookiesProvider, useCookies } from "react-cookie";
import { AuthContext } from "./context/AuthContext";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import publicRoutes from "./routes/public";
import protectedRoutes from "./routes/protected";
import API from "./api/api";

import Layout from "./containers/Layout";
import ProtectedRoute from "./components/ProtectedRoute";

function App() {
  const urlLocation = useLocation();
  const queryClient = new QueryClient();
  const { user, restoreSession, authenticated } = useContext(AuthContext);
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies();

  useEffect(() => {
    if (!urlLocation.pathname.toLowerCase().includes("callback") && cookies) {
      if (!cookies.gms_access_token) {
        navigate("/login");
      }
    }
  }, [cookies]);
  // useEffect(() => {
  //   async function checkAuth() {
  //     const { data } = await API("GET", "/auth/login/status");
  //     // console.log(data)
  //     restoreSession(data);
  //   }
  //   checkAuth();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const renderPublicRoutes = (routes) => {
    return (
      <>
        <Route index element={<Navigate to="/church" replace />} />
        {routes.map((route, i) => {
          const { path, element } = route;
          let Component = element;
          return <Route path={path} element={<Component />} key={i} />;
        })}
      </>
    );
  };

  const renderProtectedRoutes = (routes) => {
    // let resource = "operational";
    // let resource = "booking";
    // const firstRoute =
    //   resource === "operational" ? routes[0] : routes[routes.length - 1];
    // console.log("fr", firstRoute);
    const resource = ["operational", "booking"];
    const firstRoute = routes.filter((i) => {
      // if (user) return user?.Type?.permissions?.includes(i.resource);
      return resource.includes(i.resource);
      // else return true;
    })[0];

    return (
      // <>
      //   <Route
      //     index
      //     element={<Navigate to={`/app/${firstRoute?.path}`} replace />}
      //   />
      //   {routes.map((route, i) => {
      //     const { path, element } = route;
      //     let Component = element;
      //     return <Route path={path} element={<Component />} key={i} />;
      //   })}
      // </>
      <>
        <Route
          index
          element={<Navigate to={`/${firstRoute.path}`} replace />}
        />
        {routes.map((route, i) => {
          const { resource, path, element, children } = route;
          let Component = element;
          if (children)
            return (
              <Route key={i} path={path}>
                <Route
                  index
                  element={
                    <ProtectedRoute
                      resource={resource}
                      authenticatedRedirect={firstRoute.path}
                    >
                      <Component />
                    </ProtectedRoute>
                  }
                />
                {children.map((subRoute, i) => {
                  const SubComponent = subRoute.element;
                  return (
                    <Route
                      key={i}
                      path={subRoute.path}
                      element={
                        <ProtectedRoute
                          resource={resource}
                          authenticatedRedirect={firstRoute.path}
                        >
                          <SubComponent />
                        </ProtectedRoute>
                      }
                    />
                  );
                })}
              </Route>
            );
          else
            return (
              <Route
                key={i}
                path={path}
                element={
                  <ProtectedRoute
                    resource={resource}
                    authenticatedRedirect={firstRoute.path}
                  >
                    <Component />
                  </ProtectedRoute>
                }
              />
            );
        })}
      </>
    );
  };

  return (
    <QueryClientProvider client={queryClient}>
      <AccessibleNavigationAnnouncer />
      <CookiesProvider defaultSetOptions={{ path: "/" }}>
        <Routes>
          <Route path="/">
            {renderPublicRoutes(publicRoutes)}
            <Route path="*" element={<Navigate to="/login" replace />} />
            {/* <Route path="*" element={<Navigate to="/church" replace />} /> */}
          </Route>
          <Route path="/" element={<Layout />}>
            {renderProtectedRoutes(protectedRoutes)}
            <Route
              path="*"
              element={<Navigate to={`/${protectedRoutes[0].path}`} replace />}
            />
          </Route>
        </Routes>
      </CookiesProvider>
    </QueryClientProvider>
  );
}

export default App;
