import axios from "axios";
// eslint-disable-next-line no-unused-vars

// export const endpoint =
//   "https://gms-booking-api-staging-c0e5f323a274.herokuapp.com";
export const endpoint = process.env.REACT_APP_API_URL;
const apiKey = process.env.REACT_APP_CLIENT_API_KEY;

let token = localStorage.getItem("auth-token");

const instance = axios.create({
  baseURL: endpoint,
  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
    "x-api-key": apiKey,
  },
});

const API = (method, url, data, options) => {
  // return instance({ method, url, data, withCredentials: true, ...options });
  return instance({ method, url, data, ...options });
};

export default API;
