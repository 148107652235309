import { lazy } from "react";

const pages = {
  Page404: lazy(() => import("./404")),
  // GetStarted: lazy(() => import("./GetStarted")),
  //Register
  // Register: lazy(() => import("./Register")),
  // Login
  Login: lazy(() => import("./Login")),
  GMSCallback: lazy(() => import("./GMSCallback.js")),
  // Department
  Department: lazy(() => import("./Department/index.js")),
  CreateDepartment: lazy(() => import("./Department/new.js")),
  EditDepartment: lazy(() => import("./Department/edit.js")),
  DetailDepartment: lazy(() => import("./Department/detail.js")),
  // Church
  Church: lazy(() => import("./Church/index.js")),
  ChurchDetail: lazy(() => import("./Church/detail.js")),
  ChurchNewVenue: lazy(() => import("./Church/Venue/new.js")),
  ChurchVenueDetail: lazy(() => import("./Church/Venue/detail.js")),
  ChurchVenueEdit: lazy(() => import("./Church/Venue/edit.js")),
  ChurchVenueNewFacility: lazy(() => import("./Church/Venue/Facility/new.js")),
  ChurchVenueNewEquipment: lazy(() =>
    import("./Church/Venue/Equipment/new.js")
  ),
  ChurchVenueEditEquipment: lazy(() =>
    import("./Church/Venue/Equipment/edit.js")
  ),
  // ChurchVenueFacilityEdit: lazy(
  //   () => import("./Church/Venue/Facility/edit.js"),
  // ),
  //Users
  Users: lazy(() => import("./Users/index.js")),
  // CreateUser: lazy(() => import("./Users/CreateNewUser")),
  //Facility
  Facility: lazy(() => import("./Facility/index.js")),
  NewFacility: lazy(() => import("./Facility/new.js")),
  // Event
  // Onsite: lazy(() => import("./Event/OnSite/index.js")),
  // Recording: lazy(() => import("./Event/Recording/index.js")),
  Event: lazy(() => import("./Event/index.js")),
  NewEvent: lazy(() => import("./Event/new.js")),
  EventDetail: lazy(() => import("./Event/detail.js")),
  // Equipment
  Equipment: lazy(() => import("./Equipment/index.js")),
  NewEquipment: lazy(() => import("./Equipment/new.js")),
  EditEquipment: lazy(() => import("./Equipment/edit.js")),
  LightingEq: lazy(() => import("./Equipment/Lighting/index.js")),
  MultimediaEq: lazy(() => import("./Equipment/Multimedia/index.js")),
  SoundEq: lazy(() => import("./Equipment/Sound/index.js")),
  // Ministry
  GA: lazy(() => import("./Ministry/GA/index.js")),
  Multimedia: lazy(() => import("./Ministry/Multimedia/index.js")),
  Creative: lazy(() => import("./Ministry/Creative/index.js")),
  Interpreter: lazy(() => import("./Ministry/Interpreter/index.js")),
  NewMinistry: lazy(() => import("./Ministry/new.js")),
  EditMinistry: lazy(() => import("./Ministry/edit.js")),

  // Booking
  Booking: lazy(() => import("./Booking/index.js")),
  NewBooking: lazy(() => import("./Booking/new.js")),
  EditBooking: lazy(() => import("./Booking/update.js")),
  BookingDetails: lazy(() => import("./Booking/detail.js")),
  // //Profile
  // ChangePassword: lazy(() => import("./Profile/ChangePass")),
  // EditIndividualProfile: lazy(() => import("./Profile/EditIndividualProfile")),
  // EditCompanyProfile: lazy(() => import("./Profile/EditCompanyProfile.js")),
  // //Micro App
  // MicroApp: lazy(() => import("./MicroApp/index")),
  // CreateApp: lazy(() => import("./MicroApp/CreateNewApp")),
  // // AppDetails: lazy(() => import("./MicroApp/AppDetails")),
  // EditApp: lazy(() => import("./MicroApp/EditApp")),
  // //Micro App
  // Merchant: lazy(() => import("./Merchant/index.js")),
  // CreateMerchant: lazy(() => import("./Merchant/CreateMerchant.js")),
  // // AppDetails: lazy(() => import("./MicroApp/AppDetails")),
  // EditMerchant: lazy(() => import("./Merchant/EditMerchant.js")),
  // //Resource
  // Resource: lazy(() => import("./Resources/index")),
  // //Password
  // ForgotPassword: lazy(() => import("./ForgotPassword")),
  // ResetPassword: lazy(() => import("./ResetPassword")),
};

export default pages;
