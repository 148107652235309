const routes = [
  {
    resource: "operational",
    icon: "BuildingOffice",
    name: "Church",
    path: "church",
  },
  {
    resource: "operational",
    icon: "Briefcase",
    name: "Department",
    path: "department",
  },
  {
    resource: "operational",
    icon: "Megaphone",
    name: "Event Type",
    path: "event",
  },
  {
    resource: "operational",
    icon: "SquaresPlus",
    name: "Request",
    path: "booking",
  },
  {
    resource: "operational",
    icon: "UserGroup",
    name: "User Management",
    path: "user",
  },

  // {
  //   resource: "operational",
  //   icon: "ServerStack",
  //   name: "Facility",
  //   path: "facility",
  // },

  // {
  //   resource: "operational",
  //   icon: "SquaresPlus",
  //   name: "Equipment",
  //   path: "equipment",
  // },
  // {
  //   resource: "operational",
  //   icon: "Sparkles",
  //   name: "Ministry",
  //   path: "ministry",
  //   routes: [
  //     {
  //       path: "ministry/creative",
  //       name: "Creative Production",
  //     },
  //     {
  //       path: "ministry/ga",
  //       name: "General Affair",
  //     },
  //     {
  //       path: "ministry/interpreter",
  //       name: "Interpreter",
  //     },
  //     {
  //       path: "ministry/multimedia",
  //       name: "Multimedia",
  //     },
  //   ],
  // },
];

export default routes;
