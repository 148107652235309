export default {
  button: {
    primary: {
      base: "text-white bg-gray-500 border border-transparent",
      active:
        "active:bg-gray-500 hover:bg-gray-500 focus:ring focus:ring-primary",
      disabled: "opacity-50 cursor-not-allowed",
    },
    outline: {
      base: "text-primary border-primary border dark:text-primary focus:outline-none",
      active:
        "active:bg-transparent hover:border-primary focus:border-primary active:text-primary focus:ring focus:ring-primary",
      disabled: "opacity-50 cursor-not-allowed bg-gray-500",
    },
  },
  // Input
  input: {
    active:
      "focus:border-primary border-gray-200 dark:primary focus:ring focus:ring-primary dark:focus:border-primary dark:focus:ring-primary dark:bg-gray-700",
    checkbox:
      "text-primary form-checkbox focus:border-primary focus:outline-none focus:ring focus:ring-primary focus:ring-offset-0 rounded dark:focus:ring-primary ",
    radio:
      "text-primary form-radio dark:border-primary focus:border-primary focus:outline-none focus:ring focus:ring-primary focus:ring-offset-0",
  },
};
