import React, { useContext, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { SidebarContext } from "../context/SidebarContext";

import Sidebar from "../components/Sidebar";
import Header from "../components/Header";

function Layout() {
  const { isSidebarOpen, closeSidebar } = useContext(SidebarContext);
  let location = useLocation();

  useEffect(() => {
    closeSidebar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div
      className={`flex h-screen bg-layout dark:bg-gray-700 ${
        isSidebarOpen && "overflow-hidden"
      }`}
    >
      <Sidebar />
      <div className="flex flex-col flex-1 w-full bg-layout dark:bg-gray-700">
        <Header />
        <main className="h-full overflow-y-auto">
          <div id="main-container" className="max-w-container grid mx-auto">
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  );
}

export default Layout;
