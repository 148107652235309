import pages from "../pages/index";

const routes = [
  //   Department
  {
    resource: "operational",
    path: "department",
    element: pages.Department,
    children: [
      {
        path: "new",
        element: pages.CreateDepartment,
      },
      {
        path: ":id/detail",
        element: pages.DetailDepartment,
      },
      {
        path: ":id/edit",
        element: pages.EditDepartment,
      },
      {
        path: ":id/ministries/create",
        element: pages.NewMinistry,
      },
      {
        path: ":id/ministries/:ministryId/edit",
        element: pages.EditMinistry,
      },
      {
        path: ":id/equipments/create",
        element: pages.NewEquipment,
      },
      {
        path: ":id/equipments/:equipmentId/edit",
        element: pages.EditEquipment,
      },
    ],
  },
  //  User
  {
    resource: "operational",
    path: "user",
    element: pages.Users,
  },
  //  Church
  {
    resource: "operational",
    path: "church",
    element: pages.Church,
    children: [
      {
        path: ":id",
        element: pages.ChurchDetail,
      },
      {
        path: ":id/venue/new",
        element: pages.ChurchNewVenue,
      },
      {
        path: ":id/venue/:venueId",
        element: pages.ChurchVenueDetail,
      },
      {
        path: ":id/venue/:venueId/edit",
        element: pages.ChurchVenueEdit,
      },
      {
        path: ":id/venue/:venueId/facility/new",
        element: pages.ChurchVenueNewFacility,
      },
      {
        path: ":id/venue/:venueId/equipment/new",
        element: pages.ChurchVenueNewEquipment,
      },
      {
        path: ":id/venue/:venueId/equipment/:equipmentId/edit",
        element: pages.ChurchVenueEditEquipment,
      },
    ],
  },
  //  Facility
  {
    resource: "operational",
    path: "facility",
    element: pages.Facility,
    children: [
      {
        path: "new",
        element: pages.NewFacility,
      },
    ],
  },

  //  Event
  {
    resource: "operational",
    path: "event",
    element: pages.Event,
    children: [
      {
        path: ":id",
        element: pages.EventDetail,
      },
      {
        path: "new",
        element: pages.NewEvent,
      },
      {
        path: "onsite",
        element: pages.Onsite,
      },

      {
        path: "recording",
        element: pages.Recording,
      },
    ],
  },

  //  Equipment
  // {
  //   resource: "operational",
  //   path: "equipment",
  //   element: pages.LightingEq,
  //   children: [
  //     {
  //       path: "lighting",
  //       element: pages.LightingEq,
  //     },
  //     {
  //       path: "multimedia",
  //       element: pages.MultimediaEq,
  //     },
  //     {
  //       path: "sound",
  //       element: pages.SoundEq,
  //     },
  //   ],
  // },

  {
    resource: "operational",
    path: "equipment",
    element: pages.Equipment,
    children: [
      {
        path: "new",
        element: pages.NewEquipment,
      },
      {
        path: ":id/edit",
        element: pages.EditEquipment,
      },
    ],
  },

  //  Ministry
  {
    resource: "operational",
    path: "ministry",
    element: pages.LightingEq,
    children: [
      {
        path: "creative",
        element: pages.Creative,
      },
      {
        path: "GA",
        element: pages.GA,
      },
      {
        path: "interpreter",
        element: pages.Interpreter,
      },
      {
        path: "multimedia",
        element: pages.Multimedia,
      },
    ],
  },

  // Booking
  {
    resource: "operational",
    path: "booking",
    element: pages.Booking,
    children: [
      {
        path: "new",
        element: pages.NewBooking,
      },
      {
        path: ":id/update",
        element: pages.EditBooking,
      },
      {
        path: ":id",
        element: pages.BookingDetails,
      },
    ],
  },
];

export default routes;
