import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

const ProtectedRoute = ({ resource, authenticatedRedirect, children }) => {
  // const { authenticated, user } = useContext(AuthContext);
  // console.log(user)
  // if (authenticated === undefined) return null;
  const authenticated = true;
  // if (authenticated) {
  // const permissions = user?.Type?.permissions;

  // const permissions = ["booking"];
  const permissions = ["operational", "booking"];
  if (permissions?.includes(resource)) return children;
  else return <Navigate to={`/${authenticatedRedirect}`} replace />;
  // }

  // return <Navigate to="/login" replace />;
};

export default ProtectedRoute;
